import React, { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import Views from "views";
import { useLocation } from "react-router-dom";
import { CUSTOM_LAYOUT_PATH } from "utils/constants/layoutConst";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));
const StudioLayout = lazy(() => import("./StudioLayout"));

const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const location = useLocation();
  const Layout =
    token && !blankLayout
      ? CUSTOM_LAYOUT_PATH.includes(location.pathname)
        ? StudioLayout
        : AppLayout
      : AuthLayout;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Layout>
        <Views />
      </Layout>
    </Suspense>
  );
};

export default memo(Layouts);
